import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MultiSelect, {
      id: "suppliers",
      clearFilter: "",
      filter: true,
      clear: true,
      modelValue: $setup.suppliers,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.suppliers) = $event)),
      options: $setup.supplierList,
      optionLabel: "name",
      placeholder: "Üretici Seçin",
      display: "chip",
      onChange: _cache[1] || (_cache[1] = ($event: any) => ($setup.onChange($event)))
    }, null, 8, ["modelValue", "options"]),
    _createElementVNode("span", {
      class: "close-icon",
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($setup.clear && $setup.clear(...args)))
    }, "x")
  ], 64))
}