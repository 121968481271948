
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
export default {
  props: ["data", "someFunctionParent"],
  setup(props, { emit }) {
    const suppliers: any = ref(props.data);
    const store = useStore();
    store.dispatch(Actions.SUPPLIER_LIST);
    const supplierList = computed(
      () => store.state.SupplierModule.supplier.value || []
    );
    emit("testAli", {});

    const selectedCities2 = ref();
    const cities = ref([
      { name: "New York", code: "NY" },
      { name: "Rome", code: "RM" },
      { name: "London", code: "LDN" },
      { name: "Istanbul", code: "IST" },
      { name: "Paris", code: "PRS" },
    ]);
    const onChange = (args) => {
      emit("changeSupplier", args);
    };
    const clear = () => {
      suppliers.value = null;
      emit("changeSupplier", {});
    };
    return {
      close,
      suppliers,
      store,
      supplierList,
      cities,
      selectedCities2,
      clear,
      onChange,
    };
  },
};
