
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
export default {
  props: ["data"],
  setup(props, { emit }) {
    const customers: any = ref(props.data);
    const store = useStore();
    store.dispatch(Actions.CUSTOMER_LIST);
    const customerList = computed(
      () => store.state.CustomerModule.customer.value || []
    );

    const onChange = (args) => {
      emit("changeCustomer", args);
    };
    const clear = () => {
      customers.value = null;
      emit("changeCustomer", {});
    };
    return { close, customers, store, customerList,clear, onChange };
  },
};
